import request from '@/utils/request'


// 查询CGM设备配置文件列表
export function listCgmDeviceProfile(query) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/list',
    method: 'get',
    params: query
  })
}

// 查询CGM设备配置文件分页
export function pageCgmDeviceProfile(query) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/page',
    method: 'get',
    params: query
  })
}

// 查询CGM设备配置文件详细
export function getCgmDeviceProfile(data) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/detail',
    method: 'get',
    params: data
  })
}

// 新增CGM设备配置文件
export function addCgmDeviceProfile(data) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/add',
    method: 'post',
    data: data
  })
}

// 修改CGM设备配置文件
export function updateCgmDeviceProfile(data) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/edit',
    method: 'post',
    data: data
  })
}

// 删除CGM设备配置文件
export function delCgmDeviceProfile(data) {
  return request({
    url: '/cgmDeviceProfile/cgmDeviceProfile/delete',
    method: 'post',
    data: data
  })
}
